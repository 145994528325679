//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import UserIcon from "@/components/icons/UserIcon";

export default {
  name: "Students",
  components: {UserIcon},
  methods: {
    setProfile(student) {
      if (student.ID != this.currentStudent.ID) {
        this.setCurrentStudent(student)

        this.getStudentInfo()
        this.setCourses([])
        this.setDocuments([])
        this.setTrainingTest({})

        localStorage.setItem(this.storageStudentIdKey, student.ID)
      }
    },
    ...mapMutations([
      'toggleIsProfileModal',
      'toggleIsUserModal',
      'setCurrentStudent',
      'setCourses',
      'setDocuments',
      'setTrainingTest',
    ]),
    getPaddedString(string) {
      const pad = (string.length > this.infoLen) ? '...' : ''

      return string.substr(0, this.infoLen) + pad
    },
    ...mapActions([
      'getStudentInfo'
    ]),
    checkToggle(event) {
      if (this.$route.path.includes('training')) return;
      if (this.$store.state.isUserModal)
        this.toggleIsUserModal();
      event.stopPropagation();
      this.toggleIsProfileModal();
    }
  },
  data() {
    return {
      infoLen: 30
    }
  },
  computed: {
    ...mapState([
      'students',
      'currentStudent',
      'isProfileModal',
      'storageStudentIdKey'
    ]),
    ...mapGetters([
      'isPhoneAuthMethod',
    ])
  }
}
